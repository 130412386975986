import React, { useReducer, useState } from "react";
import { Image, Modal, Spinner } from "react-bootstrap";
import metmaskIcon from "../assets/images/metamask.svg";
import google from "../assets/images/google.svg";
import twitter from "../assets/images/twitter-img.svg";
import discord from "../assets/images/discord-img.svg";
import walletIcon from "../assets/images/walletconnect.svg";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { switchNetwork } from "wagmi/actions";
import { emailValidation } from "../utils/customeValidation";
import TimeCalculate from "./timeCalculate";
import Toaster from "./toaster";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const arcanaLogins = [
  { id: "google-connector", name: "google", icon: google },
  { id: "twitter-connector", name: "twitter", icon: twitter },
  { id: "discord-connector", name: "discord", icon: discord },
];
const icons= { metaMask: metmaskIcon, walletConnect: walletIcon };
export const walletState = {
  email: "",
  otp: "",
  verifying: "",
  initiatedTime: 0,
  showTimer: false,
  errors: { email: "", otp: "" },
  otpSent: 0,
  toaster:{message:'',type:''}
};

export const walletReducer = (state, action) => {
  state = state || walletState;
  switch (action.type) {
    case "setEmail":
      state = { ...state, email: action.payload };
      return state;
    case "setOtpSent":
      state = { ...state, otpSent: action.payload };
      return state;
    case "setOTP":
      state = { ...state, otp: action.payload };
      return state;
    case "setVerifying":
      state = { ...state, verifying: action.payload };
      return state;
    case "setInitiatedTime":
      state = { ...state, initiatedTime: action.payload };
      return state;
    case "setShowTimer":
      state = { ...state, showTimer: action.payload };
      return state;
    case "setErrors":
      state = { ...state, errors: action.payload };
      return state;
    case "setState":
      state = { ...state, ...action.payload };
      return state;
    case "setToaster":
      state = { ...state, toaster:action.payload };
      return state;
    default:
      return state;
  }
};

const WalletConnect = ({
  showWalletModal,
  onWalletConect,
  onWalletClose,
  onWalletError,
}: IWalletConnection) => {
  const { connectAsync, connectors } = useConnect();
  const { isConnected } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const [localState, localDispatch] = useReducer(walletReducer, walletState);
  const setToaster=(message:string,type:string='error')=>{
    localDispatch({type:'setToaster',payload:{message,type}})
  }
  const handleEmailChange = (value: any) => {
    localDispatch({
      type: "setState",
      payload: {
        ...localState,
        email: value,
        errors: {
          ...localState.errors,
          email: "",
        },
      },
    });
  };
  const handleOTPChange = (value: any) => {
    if (value && (/\D/g.test(value) || value.length > 6)) return;
    localDispatch({
      type: "setState",
      payload: {
        ...localState,
        otp: value,
        errors: {
          ...localState.errors,
          otp: "",
        },
      },
    });
  };
  const validateEmail = () => {
    if (localState.email) {
      return emailValidation(localState.email) ? "Invalid email address" : "";
    }
    return "Enter email to continue!";
  };
  const validateOtp = () => {
    if (localState.otp) {
      return "";
    }
    return "Enter otp to confirm!";
  };
  const validate = (input: string) => {
    let error: string = "";
    let isValid: boolean = true;
    switch (input) {
      case "email":
        error = validateEmail();
        break;
      case "otp":
        error = validateOtp();
        break;
      default:
        break;
    }
    if (error) {
      isValid = false;
      const errorsToUpdate = { ...localState.errors };
      errorsToUpdate[input] = error;
      localDispatch({ type: "setErrors", payload: errorsToUpdate });
    }
    return isValid;
  };
  const confirmOTP = async (connector: any) => {
    localDispatch({ type: "setVerifying", payload: "otp" });
    try {
      const isValid = validate("otp");
      if (!isValid) return;
      await connector?.auth?.loginWithOTPComplete(localState.otp, () => {
        onWalletClose();
      });
      handleConnect(connector);
    } catch (error) {
      const errorsToUpdate = { ...localState.errors };
      const message = JSON.parse(error.message);
      errorsToUpdate["otp"] =
        message["errorDescription"] || message["error"] || message;
      localDispatch({ type: "setErrors", payload: errorsToUpdate });
    } finally {
      localDispatch({ type: "setVerifying", payload: "" });
    }
  };
  const sendOTP = async (connector: any, otpSent?: number) => {
    localDispatch({ type: "setVerifying", payload: "email" });
    try {
      const isValid = validate("email");
      if (!isValid) return;
      const loginState = await connector?.auth?.loginWithOTPStart(
        localState.email
      );
      await loginState.begin();
      if (loginState.isCompleteRequired) {
        localDispatch({
          type: "setState",
          payload: {
            showTimer: true,
            initiatedTime: Math.floor(Date.now() / 1000),
            otpSent: otpSent || 1,
          },
        });
        setToaster(
          `OTP has been ${otpSent ? "resent" : "sent"} successfully!`,'success'
        );
      }
    } catch (error) {
      const errorsToUpdate = { ...localState.errors };
      errorsToUpdate["email"] =
        error["errorDescription"] || error.message || error;
      localDispatch({ type: "setErrors", payload: errorsToUpdate });
    } finally {
      localDispatch({ type: "setVerifying", payload: "" });
    }
  };
  const handleArcanaConnect = async (connector: any, connectTo: string) => {
    if (connectTo === "otp") {
      localState.otpSent ? confirmOTP(connector) : sendOTP(connector);
      return;
    }
    await connector.setLogin({
      provider: `${connectTo}`,
    });
    handleConnect(connector);
  };
  const handleConnect = async (connector: any) => {
    try {
      if (connector.id === "walletConnect") {
        onWalletClose();
      }
      if (!isConnected) {
        const { account, chain } = await connectAsync({ connector });
        if (chain.id != process.env.REACT_APP_CHAIN_ID_NUMARIC) {
          await switchNetwork({
            chainId: process.env.REACT_APP_CHAIN_ID_NUMARIC,
          });
          onWalletConect(account);
          connector.onAccountsChanged = onAccountsChanged;
          connector.onChainChanged = onChainChanged;
          onWalletClose();
        } else {
          onWalletConect(account);
          onWalletClose();
          connector.onAccountsChanged = onAccountsChanged;
          connector.onChainChanged = onChainChanged;
        }
      } else {
        onWalletClose();
        connector.onAccountsChanged = onAccountsChanged;
        connector.onChainChanged = onChainChanged;
      }
    } catch (error) {
      if (onWalletError) onWalletError(error);
      setToaster(error?.message);
    }
  };
  const onAccountsChanged = (account) => {
    onWalletConect(account);
    window.location.reload();
  };
  const onChainChanged = async () => {
    await disconnectAsync();
    window.location.reload();
  };
  const onTimerEnd = (showTimer: boolean) => {
    localDispatch({
      type: "setState",
      payload: { showTimer, initiatedTime: 0 },
    });
  };
  const renderTooltip = (props:any,name:string) => (
    <Tooltip className="text-capitalize" id={name} {...props}>
      {name}
    </Tooltip>
  );
  const [arcanaConnector, ...walletConnectors] = connectors;
  return (
    <React.Fragment>
      <Modal
        show={showWalletModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="wallet-popup"
      >
        <div className="modal-header">
          <span
            className="icon md close-icon c-pointer modal-position"
            onClick={onWalletClose}
          ></span>
        </div>
        <Modal.Body className="text-center">
          {/* {error && (
                    <div className='cust-error-bg text-start'>
                        <div className='cust-crd-mr'><Image src={errorimg} alt="" /></div>
                        <div>
                            <p className='error-title error-red'>Error</p>
                            <p className="error-desc">{error?.message}</p></div>
                    </div>
                )} */}
          <h2 className="custom-title">Connect Your Wallet</h2>
          <hr className="top-seller-hr text-center"></hr>
          <p className="custom-text">
            Connect with one of available wallet providers or create a new
            wallet.
          </p>
          <div className="">
          <div >
          {arcanaLogins.length > 0 && (
            <div className="d-flex gap-4 justify-content-center">
              {arcanaLogins.map((login: any) => {
                return (
                  <OverlayTrigger
                  placement="top"
                  overlay={(props)=>renderTooltip(props,login.name)}
                >
                  <button
                    className="wallet-login c-pointer"
                    onClick={() =>
                      handleArcanaConnect(arcanaConnector, login.name)
                    }
                    disabled={localState.verifying !== ""}
                  >
                    {/* <img src={login.icon} alt="" className="" /> */}
                    <Image src={login.icon || metmaskIcon} alt="" />
                    {/* <p className="mb-0 c-pointer">{login.name}</p> */}
                  </button>
                </OverlayTrigger>
                 
                );
              })}
            </div>
          )}
          <div className="d-flex gap-4 justify-content-center">
         <div className="aracana-divider position-relative text-center">
         <hr />
         <p><span>or continue with</span></p>
         </div>
          </div>
          <div className="d-flex gap-4 justify-content-center mt-4">
          {walletConnectors.map((connector) => (
            <>
              {" "}
              {connector.ready && (
                 <OverlayTrigger
                 placement="top"
                 // delay={{ show: 250, hide: 400 }}
                 overlay={(props)=>renderTooltip(props,connector.name)}
               >
                <button
                  className="wallet-login c-pointer"
                  onClick={() => {
                    handleConnect(connector);
                  }}
                  key={connector.id}
                >
                  <Image
                    src={icons[connector.id] || metmaskIcon}
                    alt=""
                    width={24}
                  />
                  {/* <p className="mb-0 c-pointer">{connector.name}</p> */}
                </button>
                </OverlayTrigger>
              )}
            </>
          ))}
          </div>
          <div className="d-flex gap-4 justify-content-center my-3">
         <div className="aracana-divider position-relative text-center">
         <hr />
         <p><span>or continue with</span></p>
         </div>
          </div>
          </div>
          <div className="otp-section">
            {/* <div className="relative md:w-96 mx-auto my-6"> */}
            {/* <hr /> */}
            {/* <p className="text-center w-36 absolute top-0 bg-white left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold">
              {" "}
              or continue with
            </p> */}
            {/* </div> */}
            {localState.otpSent === 0 && (
              <div>
                <input
                  type="text"
                  placeholder="Enter Email"
                  value={localState.email}
                  className="input input-style wallet-input"
                  onChange={(e) => handleEmailChange(e.target.value)}
                  disabled={localState.verifying === "email"}
                  autoFocus
                />
                {localState.errors.email && (
                  <label className="text-sm font-normal text-danger ml-4 mt-2 d-block">
                    {localState.errors.email}
                  </label>
                )}
              </div>
            )}
            {localState.otpSent > 0 && (
              <div className="">
                <input
                  type="text"
                  value={localState.otp}
                  placeholder="Enter OTP"
                  className="input input-style wallet-input"
                  onChange={(e) => handleOTPChange(e.target.value)}
                  disabled={localState.verifying === "otp"}
                  autoFocus
                />
                <div className="">
                  <div className="mt-2">
                    {localState.errors.otp && (
                      <label className="text-sm font-normal text-danger ml-4">
                        {localState.errors.otp}
                      </label>
                    )}
                  </div>
                  {localState.showTimer && (
                    <div className="mt-2">
                      <TimeCalculate
                      timerSeconds={30}
                      initiatedTime={localState.initiatedTime}
                      setTimer={onTimerEnd}
                      textToDisplay={"Resend OTP in"}
                    />
                    </div>
                  )}
                  {!localState.showTimer && (
                   <div className="text-center">
                     <button
                      className="mt-2 btn-link btn-transparent c-pointer"
                      onClick={() => sendOTP(arcanaConnector, 2)}
                      disabled={localState.showTimer}
                    >
                        <span >Resend OTP</span>
                    </button>
                   </div>
                  )}
                </div>
              </div>
            )}
            <div className="text-center mt-4">
              <button
                className="walletconnect-bg c-pointer meta-btn-style btn-primary pop-cards"
                onClick={() => handleArcanaConnect(arcanaConnector, "otp")}
                disabled={localState.verifying !== ""}
              >
                {localState.verifying !== "" && (
                  <span>
                    <Spinner size="sm" className="text-white me-2" />
                  </span>)}
                  <span className="text-white">  {localState.otpSent ? "Confirm OTP" : "Login with OTP"}</span>
                </button>
              </div>
            </div>
          </div>
          <p className="custom-text mb-2">
            we do not own private keys and cannot access your funds without your
            confirmation
          </p>
          <p className="note mt-4">
            <b>
              Note: If you encounter difficulties connecting your wallet, please
              refresh your browser and try again.
            </b>
          </p>
        </Modal.Body>
      </Modal>
      {localState.toaster.message && (
        <Toaster
          show={localState.toaster.message!==''}
          setShow={()=>setToaster('','')}
          message={localState.toaster.message}
          type={localState.toaster.type}
        />
      )}
    </React.Fragment>
  );
};
export default WalletConnect;

interface IWalletConnection {
  showWalletModal: boolean;
  onWalletClose: Function;
  onWalletConect: Function;
  onWalletError?: Function;
}
