import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import { useNavigate, useLocation } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useParams } from "react-router-dom";
import { store } from "../store";
import { setArcanaUserDetails, setUserID } from "../reducer/rootReducer";
import { getCustomer } from "../utils/api";
import { connect, useSelector } from "react-redux";
import WalletConnect from "../shared/connect.wallet";
import minnapadlogo from "../../src/assets/images/Minnapad-Logo-hor.svg";
import useArcanaAuth from "../hooks/useArcanaAuth";
function Header(props) {
  const router = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const { disconnectAsync } = useDisconnect();
  const { isConnected, address } = useAccount();
  const [url, setUrl] = useState<any>(false);
  const [userAddress, setUserAddress] = useState<any>(false);
  const isLoggedIn = useSelector(
    (state: any) => state.auth.arcanaUser?.isLoggedIn
  );
  const location = useLocation();
  const params = useParams();
  const auth = useArcanaAuth();
  const currentRoute = window.location.pathname;
  useEffect(() => {
    if (!isConnected) {
      props.dispatch(setUserID({ name: "", id: "" }));
    }
    // router(`/accounts/${address}`);
  }, [isConnected]);
  useEffect(() => {
    setUserAddress(address);
    let locationSplit: any = location.pathname.split("/");
    setUrl(locationSplit[1]);
  }, [location]);
  useEffect(() => {
    if (auth) {
      auth.provider.on("connect", handleArcanaConnect);
      auth.provider.on("disconnect", handleArcanaDisconnect);
    }
    return () => {
      auth.provider.removeListener("connect", handleArcanaConnect);
      auth.provider.removeListener("disconnect", handleArcanaDisconnect);
    };
  }, [auth]);
  const handleArcanaConnect = async () => {
    const userInfo = await auth.getUser();
    const isLoggedIn = await auth.isLoggedIn();
    store.dispatch(setArcanaUserDetails({ isLoggedIn, ...userInfo }));
  };
  const handleArcanaDisconnect = async () => {
    const isLoggedIn = await auth.isLoggedIn();
    store.dispatch(setArcanaUserDetails({ isLoggedIn }));
    await handleDisconnect();
  };
  const getCustomerDetails = async (addr: string) => {
    let response = await getCustomer(`User/CustomerDetails/${addr}`);
    if (response) {
      store.dispatch(setUserID(response.data));
    }
  };

  const handleDisconnect = async () => {
    await disconnectAsync();
    setModalShow(false);
    router("/dashboard");
  };

  const daoRedirect = (path) => {
    window.open(process.env.REACT_APP_LINK_MINNAPAD_DAO, "_blank");
    router(path);
  };
  const gotoCreate = () => {
    if (isConnected) {
      router("/create/single");
    } else {
      setModalShow(true);
    }
  };
  const redirectToMintingScreen = () => {
    window.open(process.env.REACT_APP_BRAND_WEBSITE, "_blank");
  };

  const handleMinnapad = () => {
    window.open(process.env.REACT_APP_BRAND_WEBSITE, "_blank");
  };

  const handleMarketPlacce = () => {
    router("/dashboard");
  };
  return (
    <>
      <Navbar
        className="text-white cust-head-active custom-right mobile-header-fixed"
        expand="lg"
        sticky="top"
      >
        <Container>
          <Navbar.Brand
            style={{ cursor: "pointer" }}
            onClick={() => redirectToMintingScreen()}
            className={
              currentRoute === "/minnapad/home" ? "nav-link" : "nav-link "
            }
          >
            {" "}
            <Link className="nav-link">
              <Image className="cust-logo" src={minnapadlogo} alt="logo" />
            </Link>
          </Navbar.Brand>
          {!isConnected && (
            <div className="btn-leftspace mobile-show-tb">
              {!isConnected && (
                <Button
                  className="head-wallet btn btn-primary"
                  onClick={() => setModalShow(true)}
                >
                  {!isConnected && (
                    <>
                      <span className="icon sm wallet me-2"></span>
                      <span>Connect Wallet</span>
                    </>
                  )}
                </Button>
              )}
            </div>
          )}

          {isConnected && (
            <Nav.Item
              className={`${
                isConnected ? "header-icons hide-mobile cust-change" : "d-none"
              }`}
            >
              <NavDropdown
                className="mobile-wallet-dropdown"
                title={
                  isConnected && (
                    <>
                      <Button className="btn-leftspace head-wallet after-connect mobile-wallet-connect">
                        {isConnected && (
                          <>
                            <span className="icon sm wallet me-2"></span>
                            <span>
                              {address?.slice(0, 4) +
                                "...." +
                                address?.substring(
                                  address.length - 4,
                                  address.length
                                )}
                            </span>
                          </>
                        )}
                      </Button>
                    </>
                  )
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  className={
                    userAddress === params?.useraddress ? "dark-bg" : ""
                  }
                >
                  <Link
                    to={`/accounts/${address}`}
                    className={
                      "underline" +
                      (url === "accounts"
                        ? "nav-link p-0 active-item"
                        : " nav-link p-0")
                    }
                  >
                    <span className="icon profile me-2"></span>Profile
                  </Link>
                </NavDropdown.Item>
                {/* <NavDropdown.Item className={(url === ("mycollections") ? "dark-bg" : "")}>
                  <Link to={'/mycollections'} className={"underline" + (url === ("mycollections") ? "nav-link p-0 active-item" : " nav-link p-0")}><span className='icon my-collection me-2'></span>My Collections</Link>
                </NavDropdown.Item> */}
                <NavDropdown.Item to="">
                  <div onClick={handleDisconnect}>
                    <span className="icon sm wallet-dark me-2"></span>Disconnect
                  </div>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav.Item>
          )}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="navbar-nav custom-menu global-menu-implement"
              navbarScroll
            >
              <Nav.Link
                className="p-top-mb nav-item-pd px-mobile-10 mobile-pt0"
                onClick={handleMinnapad}
              >
                {/* <Link to={'/dashboard'} className={"" + (url === ("dashboard") ? "nav-link p-0 active-link" : " nav-link ")}> */}
                Home
                {/* </Link> */}
              </Nav.Link>
              {/* <Nav.Link className=' p-top-mb px-mobile-10' >
                <span onClick={() => gotoCreate()} className={"underline" + (url === ("create") ? "nav-link p-0" : " nav-link active p-0")}>
                  Create
                </span>
              </Nav.Link> */}
              <Nav.Link
                href=""
                onClick={() => daoRedirect("/dashboard")}
                className={
                  currentRoute === "" ? "nav-link active" : "nav-link "
                }
              >
                <Link
                  className={
                    currentRoute == ""
                      ? "nav-link active px-mobile-10"
                      : "nav-link px-mobile-10"
                  }
                >
                  DAOs
                </Link>
              </Nav.Link>
              <Nav.Link
                className="p-top-mb px-mobile-10"
                onClick={handleMarketPlacce}
              >
                {/* <Link to={`/explorenfts`} className={"underline" + (url === ("explorenfts") ? "nav-link p-0" : " nav-link active p-0")}> */}
                Marketplace
                {/* </Link> */}
              </Nav.Link>
            </Nav>
            <div className="hide-icons">
              {!isConnected && (
                <Button
                  className="btn-leftspace head-wallet"
                  onClick={() => setModalShow(true)}
                >
                  {!isConnected && (
                    <>
                      <span className="icon sm wallet me-2"></span>
                      <span>Connect Wallet</span>
                    </>
                  )}
                </Button>
              )}
              <Nav.Item className="d-flex header-icons hide-mobile cust-change header-dropdown">
                {isConnected && (
                  <NavDropdown
                    className="header-dropdownmenu"
                    title={
                      isConnected && (
                        <>
                          <Button className="btn-leftspace head-wallet after-connect">
                            {isConnected && (
                              <>
                                <span className="icon sm wallet me-2"></span>
                                <span>
                                  {address?.slice(0, 4) +
                                    "...." +
                                    address?.substring(
                                      address.length - 4,
                                      address.length
                                    )}
                                </span>
                              </>
                            )}
                          </Button>
                        </>
                      )
                    }
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item
                      className={
                        userAddress === params?.useraddress ? "dark-bg" : ""
                      }
                    >
                      <Link
                        to={`/accounts/${address}`}
                        className={
                          "underline" +
                          (userAddress === params?.useraddress
                            ? "nav-link p-0 active-item"
                            : " nav-link active")
                        }
                      >
                        <span className="icon profile me-2"></span>Profile
                      </Link>
                    </NavDropdown.Item>
                    {auth?.connected && isLoggedIn && (
                      <NavDropdown.Item
                        className={
                          userAddress === params?.useraddress ? "" : ""
                        }
                        onClick={() => auth?.showWallet()}
                      >
                        <span className="icon profile me-2"></span>Wallet
                      </NavDropdown.Item>
                    )}
                    {/* <NavDropdown.Item className={(url === ("mycollections") ? "dark-bg" : "")}>
                      <Link to={'/mycollections'} className={"underline" + (url === ("mycollections") ? "nav-link p-0 active-item" : " nav-link active")}><span className='icon my-collection me-2'></span>My Collections</Link>
                    </NavDropdown.Item> */}
                    <NavDropdown.Item onClick={handleDisconnect}>
                      <Link className="nav-link">
                        {isConnected && (
                          <span>
                            {isConnected && (
                              <>
                                <span className="icon sm wallet-dark me-2 ms-1"></span>
                                Disconnect
                              </>
                            )}
                          </span>
                        )}
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </Nav.Item>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <WalletConnect
        showWalletModal={modalShow}
        onWalletConect={(addr) => getCustomerDetails(addr)}
        onWalletClose={() => setModalShow(false)}
      />
    </>
  );
}

const connectStateToProps = ({ auth }: any) => {
  return { auth: auth };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(Header);
