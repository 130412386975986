import React from 'react'
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
const icons={
    success:'check-circle',
    error:'error'
}
const Toaster = ({show,message,delayTimeout=3000,type,setShow}) => {
    setTimeout(()=>{
        setShow(false)
    },delayTimeout)
  return (
    <div className="p-absolute toaster-center">
    <ToastContainer className="p-3 cust-nametoster position-fixed bottom-0 ">
      <Toast show={show} className="text-center toster-component">
        <Toast.Body className="toaster-cust">
          <span className={`icon mt-0 md ${icons[type]} me-2`}></span>{" "}
          <span>{message}</span>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  </div>
  )
}

export default Toaster