import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Provider } from 'react-redux';
import { createConfig, WagmiConfig, configureChains } from 'wagmi';
import { polygon } from 'wagmi/chains';
import { AuthProvider } from "@arcana/auth";
import { ArcanaConnector } from "@arcana/auth-wagmi";
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import "./assets/css/styles.css"
import { Web3Modal } from '@web3modal/react';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { amoyNetwork } from './utils/amoyNetwork';
import { publicProvider } from 'wagmi/providers/public';
const walletConnectProjectId: any = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
const { chains, publicClient } = configureChains(
  [polygon, amoyNetwork],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: process.env.REACT_APP_ENV === "production" ? `https://polygon.llamarpc.com` : `https://rpc.ankr.com/polygon_amoy`,
      }),
    }),
    publicProvider(),
  ],
)
const arcanaClientId: string = process.env.REACT_APP_ARCANA_CLIENT_ID || "";
let auth: AuthProvider | null=null;
if (!auth) {
  auth = new AuthProvider(`${arcanaClientId}`, { 
    position: 'right',     
    theme: 'light',
    alwaysVisible: false, 
    debug:false,   
    setWindowProvider: true,
    connectOptions: {
      compact: true 
    },
    // chainConfig: {
    //   chainId: process.env.REACT_APP_CHAIN_ID_NUMARIC,        
    //   rpcUrl: process.env.REACT_APP_RPC_URL,
    // },
});
}
window.onload = async function() {
  try{
    await auth?.init()
  }catch(error){
  }
};
export {auth}
export const arcana = (chains) => {
  return new ArcanaConnector({
    chains,
    options: {
      auth: auth,
    },
  });
};
const config = createConfig({
  autoConnect: true,
  connectors: [
    arcana(chains),
    new InjectedConnector({
      chains, options: {
        name: "Browser Wallet"
      }
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: walletConnectProjectId,
        metadata: {
          name: "Minnapad",
          description: "Minnapad is a Create-to-Earn DAOs' launchpad and NFT marketplace that provides access to legendary Japanese creators for the wider entertainment community.",
          icons: ["https://devmpstorageaccount.blob.core.windows.net/minnapad/minnapad-color-logo.svg"],
          url: "https://minnapad.com"
        }
      }
    })
  ],
  publicClient
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiConfig config={config}>
        <App />
      </WagmiConfig>
      <Web3Modal
        themeVariables={{
          '--w3m-z-index': "9999px",
          '--w3m-background-color': '#0067FD'
        }}
        projectId={walletConnectProjectId}
      />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
